.profile-container {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  min-height: 100vh;
}

.profile-parent {
  display: flex;
  align-items: center;
  color: aliceblue;
}
.profile-details-name {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-size: 5em;
  font-family: 'Montserrat';
  background-color: #00000088;
}

.profile-details-role {
  margin: 1rem 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.profile-details-role h1 {
  font-size: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.primary-text {
  color: white;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

.primary-text-fixed {
  margin-right: 1rem;
  font-size: 1em;
  color: white;
}

.primary-text-typing {
  font-size: 1em;
  color: red;
  text-align: left;
  vertical-align: middle;
  position: relative;
}

.profile-options .highlighted-btn {
  margin: 0 2rem;
}

.profile-options .highlighted-btn:hover {
  background-color: cornsilk;
  color: 111;
}

.profile-picture {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 0 0 white;
  border-radius: 50%;
  height: 380px;
  width: 380px;
  margin: 0 0 0 1rem;
}

.profile-picture-background {
  height: 92%;
  width: 92%;
  background-image: url(../../../assets/Home/profile-pic.jpg);
  background-size: cover;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
}

.colz-icon i {
  color: white;
  padding: 1rem;
}

.colz-icon i:hover {
  transform: scale(1.5);
}

@media screen and (max-width: 1110px) {
  .profile-picture {
    margin: 0 0 0 80px;
  }
  .profile-details-name {
    font-size: 20px;
  }
  .profile-details-role .primary-text {
    font-size: 12px;
  }
  .profile-options .btn {
    width: 120px;
    padding: 11px 0;
    z-index: 70;
  }
}

@media screen and (max-width: 910px) {
  .profile-picture {
    height: 320px;
    width: 320px;
    margin: 0 0 0 4px;
  }
}
@media screen and (max-width: 810px) {
  .profile-picture {
    margin: 0 !important;
  }
  .profile-parent {
    flex-direction: column-reverse;
    margin: 40px 0 0 0;
  }
  .profile-options {
    display: flex;
    justify-content: space-between;
  }
  .profile-details {
    margin: 25px 0 0;
  }
  .profile-options .highlighted-btn {
    margin: 0 !important;
  }
  .profile-options .profile-details-role {
    text-align: center;
  }
}

@media screen and (max-width: 375px) {
  .profile-picture {
    height: 275px;
    width: 275px;
    margin-top: -90px;
  }
  .profile-details {
    width: 90%;
  }
}
