.about-me-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  font-family: 'Montserrat';
  min-height: 100vh;
}

.about-me-parent {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-me-card {
  display: flex;
  flex-direction: row;
  width: 1000px;
  box-shadow: 0 0 20px -2px #1f2335;
  height: 50vh;
}

.about-me-profile {
  width: 50%;
  background-image: url('../../assets/AboutMe/profile.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.about-me-details {
  width: 48%;
  text-align: justify;
  padding: 1rem;
}

.about-me-description {
  font-weight: 500;
  font-size: 0.8em;
}

.about-me-highlights {
  margin: 1rem 0;
}

.highlight-heading {
  font-weight: 600;
  font-size: 1em;
  margin: 0 0 5px 0;
}

.highlight {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  font-size: 0.9em;
}

.highlight-blob {
  height: 5px;
  width: 5px;
  margin: 0.5rem 1rem 0.5rem 0;
  background-color: #ff5823;
  border-radius: 50%;
}

@media only screen and (max-width: 1110px) {
  .about-me-parent {
    width: 88%;
  }
  .about-me-options {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 898px) {
  .about-me-profile {
    display: none;
  }
  .about-me-details {
    width: 100%;
  }
}

@media only screen and (max-width: 466px) {
  .about-me-options {
    display: flex;
    flex-direction: column;
  }
  .about-me-options .highlighted-btn {
    margin: 24px 0 0 0 !important;
  }

  .about-me-options .primary-btn {
    margin: 24px 0 0 0;
  }
  .about-me-options a {
    width: 100%;
  }
  .about-me-options button {
    width: 100%;
  }
}
