@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');
@font-face {
  font-family: 'Poppins Medium';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Poppins-Medium.ttf');
}

@font-face {
  font-family: 'Poppins Regular';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins SemiBold';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: 'Poppins Bold';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Poppins-Bold.ttf');
}

@font-face {
  font-family: 'Poppins ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Poppins-ExtraBold.ttf');
}

@font-face {
  font-family: 'Poppins Light';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Poppins-Light.ttf');
}

@font-face {
  font-family: 'Poppins Thin';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Poppins-Thin.ttf');
}

@font-face {
  font-family: 'Roboto Bold';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Roboto-Bold.ttf');
}
@font-face {
  font-family: 'Roboto Light';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Roboto-Light.ttf');
}
@font-face {
  font-family: 'Roboto Medium';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Roboto-Medium.ttf');
}
@font-face {
  font-family: 'Roboto Regular';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Roboto-Regular.ttf');
}
@font-face {
  font-family: 'Roboto Bold';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Roboto-Black.ttf');
}
@font-face {
  font-family: 'Roboto Bold';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Roboto-Black.ttf');
}

html,
body {
  height: 100%;
  font-size: 100%;
}

* {
  outline: none;
}

body {
  margin: 0;
  overflow-y: overlay;
  font-family: 'Poppins Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

body ::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 100%, 0.16);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-track {
  background: hsla(0, 0%, 100%, 0.1);
}

.btn {
  padding: 20px 60px;
  font-size: 1rem;
  cursor: pointer;
  transition: 0.2s;
  font-family: 'Montserrat';
  font-weight: 800;
  width: 230px;
}

.btn:active {
  transform: translateY(2px);
}

.primary-btn {
  color: var(--white);
  border: 2px solid linen;
  background-color: #1f223500;
}
/* @media only screen and (max-width: 414px) {
  .primary-btn {
    font-size: 13px;
  }
} */

.primary-btn:hover {
  color: aliceblue;
  border: 2px solid #ff5823;
  background-color: #1f2235;
}

.highlighted-btn {
  color: var(--white);
  background-color: #ff5823;
}
@media only screen and (max-width: 568px) {
  .highlighted-btn {
    z-index: -900;
  }
}

.red-border {
  border: 1px solid var(--error-message) !important;
}

.fade-in {
  opacity: 0;
  transform: translateY(80px);
  transition: all 0.8s ease;
}
