.heading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Montserrat';
  width: 100%;
  margin: 0 0 50px 0;
}

.screen-sub-heading {
  font-weight: 500;
  letter-spacing: 3px;
  margin: 8px 0 18px 0;
  font-size: 1rem;
  color: black;
}

.screen-heading {
  font-size: 2.5rem;
  font-weight: 700;
}

.heading-separator {
  display: flex;
  align-items: center;
  position: relative;
  width: 180px;
  margin: 10px 0 0 0;
}

.separator-line {
  width: 100%;
  height: 2px;
  background-color: #1f2235;
}

.separator-blob {
  height: 10px;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
}

.separator-blob div {
  width: 50px;
  position: relative;
  top: -3px;
  border-radius: 10px;
  background-color: red;
}
