.main-container {
  padding: 100px 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.central-form {
  max-width: 1100px;
  padding: 10px;
  display: flex;
  border-radius: 12px;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  background-color: #1f2235;
}

.central-form h2 {
  color: white;
  letter-spacing: 0.2rem;
  margin-bottom: 20px;
}
.back-form {
  max-width: 380px;
  margin: 0 auto;
}

.back-form .img-back {
  width: 100%;
  margin-bottom: 20px;
}
.back-form .img-back h4 {
  color: white;
  letter-spacing: 0.3rem;
  font-weight: 400;
  font-size: 18px;
  opacity: 0.5;
}
.back-form .img-back img {
  width: 100%;
  opacity: 0.5;
}
.central-form form {
  width: auto;
  display: flex;
  color: black;
  padding: 15px;
  justify-content: center;
  flex-direction: column;
  margin-top: -52px;
  background-color: white;
  border-radius: 12px;
}
.central-form form p {
  text-align: center;
  margin-bottom: 10px;
  color: darkorange;
  letter-spacing: 0.4rem;
  font-family: 'Poppins Bold';
}

.central-form form label {
  margin-bottom: 5px;
  color: black;
  letter-spacing: 0.2rem;
}

.central-form form label {
  margin-bottom: 5px;
  color: black;
  letter-spacing: 0.2rem;
}

.central-form form input,
textarea {
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0);
  outline: none;
  background-color: rgba(230, 230, 230, 0.6);
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  margin-bottom: 22px;
  border-radius: 10px;
  transition: 0.3s;
}

.central-form form textarea:focus {
  border: 2px solid rgba(30, 85, 250, 0.47);
  background-color: white;
}

.central-form form input:focus {
  border: 2px solid rgba(30, 85, 250, 0.47);
  background-color: white;
}

.send-btn {
  display: flex;
  margin: auto;
}

form button {
  padding: 10px;
  width: 175px;
  border: 2px solid black;
  font-size: 1rem;
  background-color: white;
  align-items: center;
  justify-content: center;
  transition: all ease 1s;
}

form button:hover {
  border: 2px solid red;
}

.img-back {
  flex: 1;
  align-self: center;
  margin-right: 20px;
}
form {
  flex: 0.9;
}

button .load {
  width: 100%;
}
button .load img {
  width: 60px;
  color: green;
}

.col i {
  padding: 15px;
  color: white;
}

.col i:hover {
  transform: scale(1.5);
}

@media screen and (min-width: 940px) {
  .back-form {
    max-width: 1100px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  .img-back {
    flex: 1;
    align-self: center;
    margin-right: 20px;
  }
  form {
    flex: 0.9;
  }
}
