@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap);
@font-face {
  font-family: 'Poppins Medium';
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/Poppins-Medium.9e1bb626.ttf);
}

@font-face {
  font-family: 'Poppins Regular';
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/Poppins-Regular.8081832f.ttf);
}

@font-face {
  font-family: 'Poppins SemiBold';
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/Poppins-SemiBold.cce5625b.ttf);
}

@font-face {
  font-family: 'Poppins Bold';
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/Poppins-Bold.404e299b.ttf);
}

@font-face {
  font-family: 'Poppins ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/Poppins-ExtraBold.4ed78cd5.ttf);
}

@font-face {
  font-family: 'Poppins Light';
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/Poppins-Light.f41b63c0.ttf);
}

@font-face {
  font-family: 'Poppins Thin';
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/Poppins-Thin.a61eaf4c.ttf);
}

@font-face {
  font-family: 'Roboto Bold';
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/Roboto-Bold.75371f53.ttf);
}
@font-face {
  font-family: 'Roboto Light';
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/Roboto-Light.8be2d148.ttf);
}
@font-face {
  font-family: 'Roboto Medium';
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/Roboto-Medium.7429a63c.ttf);
}
@font-face {
  font-family: 'Roboto Regular';
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/Roboto-Regular.a8d6ac03.ttf);
}
@font-face {
  font-family: 'Roboto Bold';
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/Roboto-Black.2a428584.ttf);
}
@font-face {
  font-family: 'Roboto Bold';
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/Roboto-Black.2a428584.ttf);
}

html,
body {
  height: 100%;
  font-size: 100%;
}

* {
  outline: none;
}

body {
  margin: 0;
  overflow-y: overlay;
  font-family: 'Poppins Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

body ::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 100%, 0.16);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-track {
  background: hsla(0, 0%, 100%, 0.1);
}

.btn {
  padding: 20px 60px;
  font-size: 1rem;
  cursor: pointer;
  transition: 0.2s;
  font-family: 'Montserrat';
  font-weight: 800;
  width: 230px;
}

.btn:active {
  transform: translateY(2px);
}

.primary-btn {
  color: var(--white);
  border: 2px solid linen;
  background-color: #1f223500;
}
/* @media only screen and (max-width: 414px) {
  .primary-btn {
    font-size: 13px;
  }
} */

.primary-btn:hover {
  color: aliceblue;
  border: 2px solid #ff5823;
  background-color: #1f2235;
}

.highlighted-btn {
  color: var(--white);
  background-color: #ff5823;
}
@media only screen and (max-width: 568px) {
  .highlighted-btn {
    z-index: -900;
  }
}

.red-border {
  border: 1px solid var(--error-message) !important;
}

.fade-in {
  opacity: 0;
  transform: translateY(80px);
  transition: all 0.8s ease;
}

.root {
  --light-blue: #04c3ff;
  --light-blue-shadow: rgb(69 211 255 / 27%);
  --sea-green: #0abead;
  --sea-green-dark: #0cbfae;
  --sea-green-extra-dark: #09bead;
  --sea-green-shadow: rgba(11, 190, 173, 0.4);
  --header-hamburger-background: rgba(11, 190, 173, 0.8);
  --orange: #f57f17;
  --dark-orange: #ff5823;
  --orange-shadow: rgba(245, 127, 23, 0.2);
  --white: #ffffff;
  --black: #333333;
  --disabled-btn: #09bead7a;
  --error-message: #e44f4fd1;
  --toast-error: #e44f4ffa;
  --toast-shadow: #9c9c9ce3;
  --toast-success: #0cbfae;
  --loader-color: #0cbfae;
}

.profile-container {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  min-height: 100vh;
}

.profile-parent {
  display: flex;
  align-items: center;
  color: aliceblue;
}
.profile-details-name {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-size: 5em;
  font-family: 'Montserrat';
  background-color: #00000088;
}

.profile-details-role {
  margin: 1rem 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.profile-details-role h1 {
  font-size: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.primary-text {
  color: white;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

.primary-text-fixed {
  margin-right: 1rem;
  font-size: 1em;
  color: white;
}

.primary-text-typing {
  font-size: 1em;
  color: red;
  text-align: left;
  vertical-align: middle;
  position: relative;
}

.profile-options .highlighted-btn {
  margin: 0 2rem;
}

.profile-options .highlighted-btn:hover {
  background-color: cornsilk;
  color: 111;
}

.profile-picture {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 0 0 white;
  border-radius: 50%;
  height: 380px;
  width: 380px;
  margin: 0 0 0 1rem;
}

.profile-picture-background {
  height: 92%;
  width: 92%;
  background-image: url(/static/media/profile-pic.e824f7fa.jpg);
  background-size: cover;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
}

.colz-icon i {
  color: white;
  padding: 1rem;
}

.colz-icon i:hover {
  transform: scale(1.5);
}

@media screen and (max-width: 1110px) {
  .profile-picture {
    margin: 0 0 0 80px;
  }
  .profile-details-name {
    font-size: 20px;
  }
  .profile-details-role .primary-text {
    font-size: 12px;
  }
  .profile-options .btn {
    width: 120px;
    padding: 11px 0;
    z-index: 70;
  }
}

@media screen and (max-width: 910px) {
  .profile-picture {
    height: 320px;
    width: 320px;
    margin: 0 0 0 4px;
  }
}
@media screen and (max-width: 810px) {
  .profile-picture {
    margin: 0 !important;
  }
  .profile-parent {
    flex-direction: column-reverse;
    margin: 40px 0 0 0;
  }
  .profile-options {
    display: flex;
    justify-content: space-between;
  }
  .profile-details {
    margin: 25px 0 0;
  }
  .profile-options .highlighted-btn {
    margin: 0 !important;
  }
  .profile-options .profile-details-role {
    text-align: center;
  }
}

@media screen and (max-width: 375px) {
  .profile-picture {
    height: 275px;
    width: 275px;
    margin-top: -90px;
  }
  .profile-details {
    width: 90%;
  }
}

.footer-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-top: -10rem;
}

.footer-parent {
  width: 100%;
  height: 100%;
  max-width: 1920px;
}

.footer-parent img {
  max-width: 100%;
  height: 20vh;
}

@media only screen and (max-width: 1110px) {
  .footer-parent {
    display: flex;
    align-items: flex-end;
  }
}

.home-container {
  background: url(/static/media/hero-bg.bfe6fa00.png) no-repeat center fixed;
  background-size: cover;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 0 100px 0;
}

.about-me-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  font-family: 'Montserrat';
  min-height: 100vh;
}

.about-me-parent {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-me-card {
  display: flex;
  flex-direction: row;
  width: 1000px;
  box-shadow: 0 0 20px -2px #1f2335;
  height: 50vh;
}

.about-me-profile {
  width: 50%;
  background-image: url(/static/media/profile.286a5d88.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.about-me-details {
  width: 48%;
  text-align: justify;
  padding: 1rem;
}

.about-me-description {
  font-weight: 500;
  font-size: 0.8em;
}

.about-me-highlights {
  margin: 1rem 0;
}

.highlight-heading {
  font-weight: 600;
  font-size: 1em;
  margin: 0 0 5px 0;
}

.highlight {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  font-size: 0.9em;
}

.highlight-blob {
  height: 5px;
  width: 5px;
  margin: 0.5rem 1rem 0.5rem 0;
  background-color: #ff5823;
  border-radius: 50%;
}

@media only screen and (max-width: 1110px) {
  .about-me-parent {
    width: 88%;
  }
  .about-me-options {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 898px) {
  .about-me-profile {
    display: none;
  }
  .about-me-details {
    width: 100%;
  }
}

@media only screen and (max-width: 466px) {
  .about-me-options {
    display: flex;
    flex-direction: column;
  }
  .about-me-options .highlighted-btn {
    margin: 24px 0 0 0 !important;
  }

  .about-me-options .primary-btn {
    margin: 24px 0 0 0;
  }
  .about-me-options a {
    width: 100%;
  }
  .about-me-options button {
    width: 100%;
  }
}

.heading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Montserrat';
  width: 100%;
  margin: 0 0 50px 0;
}

.screen-sub-heading {
  font-weight: 500;
  letter-spacing: 3px;
  margin: 8px 0 18px 0;
  font-size: 1rem;
  color: black;
}

.screen-heading {
  font-size: 2.5rem;
  font-weight: 700;
}

.heading-separator {
  display: flex;
  align-items: center;
  position: relative;
  width: 180px;
  margin: 10px 0 0 0;
}

.separator-line {
  width: 100%;
  height: 2px;
  background-color: #1f2235;
}

.separator-blob {
  height: 10px;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
}

.separator-blob div {
  width: 50px;
  position: relative;
  top: -3px;
  border-radius: 10px;
  background-color: red;
}

.resume-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 100px 0;
  min-height: 100vh;
  font-family: 'Montserrat';
  background: url(/static/media/resume-bg.e591a57d.jpg) no-repeat center;
  background-size: cover;
}

.resume-container::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.resume-card {
  opacity: 1;
  background-color: white;
  display: flex;
  height: 50vh;
  width: 1000px;
  box-shadow: 15px 15px 15px -15px #1f2235;
  padding-right: 20px;
}

.resume-bullets {
  height: 100%;
  box-shadow: 15px 0 9px -15px #1f2235;
  width: 320px;
}

.resume-bullets-details {
  flex-grow: 1;
  width: 900px;
  overflow: hidden;
  padding: 0 0 0 70px;
}

.resume-details-carousel {
  transition: transform 1s ease-out;
}

.bullet-container {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
}

.bullets {
  width: 80%;
  position: relative;
  z-index: 2;
}

.bullet {
  display: flex;
  align-items: center;
  background-color: #1f2235;
  height: 40px;
  margin: 15px 0;
  padding: 0 8px;
  border-radius: 20px;
  width: 0px;
  transition: width 0.6s ease;
  cursor: pointer;
}

.bullet-label {
  font-weight: 500;
  white-space: nowrap;
  font-family: 'Montserrat';
}
.bullet-logo {
  height: 16px;
  margin: 0 30px 0 0;
}
.bullet-icons {
  width: 34px;
  height: 100%;
  z-index: 1;
  background-color: #1f2335;
  position: absolute;
}

.selected-bullet {
  color: white;
  width: 120%;
}

.resume-screen-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 50vh;
  width: 100%;
}

.resume-heading {
  display: flex;
  flex-direction: column;
}

.resume-main-heading {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.heading-bullet {
  position: absolute;
  left: -30px;
  height: 15px;
  width: 15px;
  top: 5px;
  background-color: black;
  border-radius: 50%;
}

span {
  font-weight: 500;
}

.resume-sub-heading {
  font-weight: 600;
  color: black;
  margin: 10px 0 0 0;
}
.resume-heading-description {
  font-size: 12px;
  text-align: justify;
}

.heading-date {
  background-color: #00203f;
  height: 28px;
  text-align: center;
  padding: 0px 12px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 14px;
  color: white;
}

.skill-parent {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 50%;
}

.programming-skill-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 100%;
}

.skill-percentage {
  border: solid 1px black;
  position: relative;
  height: 15px;
  width: 50%;
  background-color: #00203f;
}

.skill-percentage .active-percentage {
  height: 15px;
  transition: 0.8s ease;
  position: absolute;
  background-color: #adefd1;
}

.skill-parent span {
  font-family: 'Montserrat';
  font-size: 1rem;
  font-weight: 500;
  color: black;
}

@media only screen and (max-width: 1110px) {
  .resume-card {
    width: 880px;
  }
  .resume-bullets-details {
    padding: 0 0 0 70px;
  }
}

@media only screen and (max-width: 1023px) {
  .resume-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .bullets {
    width: 90%;
  }
  .resume-card {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    align-items: center;
    width: 90%;
  }
  .resume-bullets-details {
    height: 360px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0;
  }
  .resume-bullets {
    width: 100%;
    margin: 30px 0;
  }
  .selected-bullet {
    width: 100%;
  }
}

.projects-container {
  padding: 100px 0;
  min-height: 100vh;
  font-family: 'Montserrat';
  display: flex;
  flex-direction: column;
}

.projects-section {
  padding: 2rem 0;
  background-image: url(/static/media/bg.a3c5d494.jpg);
  background-position: center;
  background-attachment: fixed;
  position: relative;
  z-index: 1;
  height: 50;
}

.projects-section::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.85;
  background-color: #1f2235;
}

.footer-image {
  width: 100%;
  height: 100%;
  max-width: 1920px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.footer-image img {
  max-width: 100%;
  height: 130px;
}

.projects-section .projects-item {
  background-color: white;
  padding: 1rem;
  border-radius: 50px 50px 50px 0px;
}

.projects-section .projects-item p {
  font-size: 1em;
  line-height: 1.5rem;
}

.projects-section .projects-info {
  position: relative;
  padding-left: 5rem;
  padding-top: 1rem;
  height: 6rem;
}

.projects-section .projects-info img {
  height: 100px;
  width: 100px;
  border: 2px solid transparent;
  border-color: black;
  left: 0;
  top: 0;
  border-radius: 50%;
  position: absolute;
  transition: 1s;
}

/* .projects-section .projects-info img:hover {
  margin-top: 170px;
  transform: scale(5);
  overflow: auto;
  z-index: 999;
} */

.projects-section .projects-info h5 {
  font-weight: 600;
  line-height: 26px;
  margin: 0 1.5rem 0;
}

.projects-item {
  height: 420px;
}

.projects-libraries {
  height: 50px;
  padding-top: 10px;
  font-weight: 600;
  text-align: center;
  justify-content: space-between;
}

.main-container {
  padding: 100px 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.central-form {
  max-width: 1100px;
  padding: 10px;
  display: flex;
  border-radius: 12px;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  background-color: #1f2235;
}

.central-form h2 {
  color: white;
  letter-spacing: 0.2rem;
  margin-bottom: 20px;
}
.back-form {
  max-width: 380px;
  margin: 0 auto;
}

.back-form .img-back {
  width: 100%;
  margin-bottom: 20px;
}
.back-form .img-back h4 {
  color: white;
  letter-spacing: 0.3rem;
  font-weight: 400;
  font-size: 18px;
  opacity: 0.5;
}
.back-form .img-back img {
  width: 100%;
  opacity: 0.5;
}
.central-form form {
  width: auto;
  display: flex;
  color: black;
  padding: 15px;
  justify-content: center;
  flex-direction: column;
  margin-top: -52px;
  background-color: white;
  border-radius: 12px;
}
.central-form form p {
  text-align: center;
  margin-bottom: 10px;
  color: darkorange;
  letter-spacing: 0.4rem;
  font-family: 'Poppins Bold';
}

.central-form form label {
  margin-bottom: 5px;
  color: black;
  letter-spacing: 0.2rem;
}

.central-form form label {
  margin-bottom: 5px;
  color: black;
  letter-spacing: 0.2rem;
}

.central-form form input,
textarea {
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0);
  outline: none;
  background-color: rgba(230, 230, 230, 0.6);
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  margin-bottom: 22px;
  border-radius: 10px;
  transition: 0.3s;
}

.central-form form textarea:focus {
  border: 2px solid rgba(30, 85, 250, 0.47);
  background-color: white;
}

.central-form form input:focus {
  border: 2px solid rgba(30, 85, 250, 0.47);
  background-color: white;
}

.send-btn {
  display: flex;
  margin: auto;
}

form button {
  padding: 10px;
  width: 175px;
  border: 2px solid black;
  font-size: 1rem;
  background-color: white;
  align-items: center;
  justify-content: center;
  transition: all ease 1s;
}

form button:hover {
  border: 2px solid red;
}

.img-back {
  flex: 1 1;
  align-self: center;
  margin-right: 20px;
}
form {
  flex: 0.9 1;
}

button .load {
  width: 100%;
}
button .load img {
  width: 60px;
  color: green;
}

.col i {
  padding: 15px;
  color: white;
}

.col i:hover {
  transform: scale(1.5);
}

@media screen and (min-width: 940px) {
  .back-form {
    max-width: 1100px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  .img-back {
    flex: 1 1;
    align-self: center;
    margin-right: 20px;
  }
  form {
    flex: 0.9 1;
  }
}

.header-container {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100px;
  color: var(--white);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.header-parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 70%;
  color: white;
}

.header-logo {
  letter-spacing: 4px;
  font-family: 'Montserrat';
  font-weight: 500;
  color: white;
}

.header-logo span {
  color: red;
}

.header-options {
  display: flex;
  align-items: center;
}

.header-option {
  font-size: 20px;
  cursor: pointer;
  font-weight: 600;
}

.header-hamburger {
  display: none;
  color: white;
}

.header-option-separator {
  margin: 0 50px 0 0;
  color: white;
}

.selected-header-option {
  color: rebeccapurple;
}

@media only screen and (max-width: 1110px) {
  .header-parent {
    width: 80%;
  }
}

@media only screen and (max-width: 970px) {
  .header-hamburger {
    display: block;
  }
  .header-parent {
    width: 100%;
    padding: 0 40px;
    position: relative;
  }
  .header-options {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: -100%;
    top: 0px;
    height: 100vh;
    width: 100%;
    justify-content: space-around;
    background-color: #1f2235;
    z-index: 1000;
    opacity: 0.8;
    transition: all 0.8s ease-out;
  }
  .header-option-separator {
    margin: 0;
  }
  .selected-header-option {
    color: var(--white);
  }
  .header-option {
    color: var(--white);
  }
  .header-hamburger-bars {
    font-size: 30px;
    cursor: pointer;
    margin: 0px 10px;
    color: var(--white);
  }
  .show-hamburger-options {
    left: 0;
    opacity: 1;
  }
}

.go-home-btn {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: solid 1px black;
  background-color: white;
  position: fixed;
  right: 25px;
  bottom: 25px;
}

.go-home-btn:hover {
  border: solid 1px grey;
}

