.footer-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-top: -10rem;
}

.footer-parent {
  width: 100%;
  height: 100%;
  max-width: 1920px;
}

.footer-parent img {
  max-width: 100%;
  height: 20vh;
}

@media only screen and (max-width: 1110px) {
  .footer-parent {
    display: flex;
    align-items: flex-end;
  }
}
