.projects-container {
  padding: 100px 0;
  min-height: 100vh;
  font-family: 'Montserrat';
  display: flex;
  flex-direction: column;
}

.projects-section {
  padding: 2rem 0;
  background-image: url('../../assets/Projects/bg.jpg');
  background-position: center;
  background-attachment: fixed;
  position: relative;
  z-index: 1;
  height: 50;
}

.projects-section::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.85;
  background-color: #1f2235;
}

.footer-image {
  width: 100%;
  height: 100%;
  max-width: 1920px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.footer-image img {
  max-width: 100%;
  height: 130px;
}

.projects-section .projects-item {
  background-color: white;
  padding: 1rem;
  border-radius: 50px 50px 50px 0px;
}

.projects-section .projects-item p {
  font-size: 1em;
  line-height: 1.5rem;
}

.projects-section .projects-info {
  position: relative;
  padding-left: 5rem;
  padding-top: 1rem;
  height: 6rem;
}

.projects-section .projects-info img {
  height: 100px;
  width: 100px;
  border: 2px solid transparent;
  border-color: black;
  left: 0;
  top: 0;
  border-radius: 50%;
  position: absolute;
  transition: 1s;
}

/* .projects-section .projects-info img:hover {
  margin-top: 170px;
  transform: scale(5);
  overflow: auto;
  z-index: 999;
} */

.projects-section .projects-info h5 {
  font-weight: 600;
  line-height: 26px;
  margin: 0 1.5rem 0;
}

.projects-item {
  height: 420px;
}

.projects-libraries {
  height: 50px;
  padding-top: 10px;
  font-weight: 600;
  text-align: center;
  justify-content: space-between;
}
